export { matchers } from './client-matchers.js';

export const components = [
	() => import("..\\..\\src\\routes\\__layout.svelte"),
	() => import("..\\..\\src\\routes\\__error.svelte"),
	() => import("..\\..\\src\\routes\\achievement\\index.svelte"),
	() => import("..\\..\\src\\routes\\artifacts\\[id].svelte"),
	() => import("..\\..\\src\\routes\\artifacts\\index.svelte"),
	() => import("..\\..\\src\\routes\\banners\\index.svelte"),
	() => import("..\\..\\src\\routes\\calculator\\index.svelte"),
	() => import("..\\..\\src\\routes\\calendar\\index.svelte"),
	() => import("..\\..\\src\\routes\\changelog.svelte"),
	() => import("..\\..\\src\\routes\\characters\\[id].svelte"),
	() => import("..\\..\\src\\routes\\characters\\index.svelte"),
	() => import("..\\..\\src\\routes\\domains\\[id].svelte"),
	() => import("..\\..\\src\\routes\\fishing\\index.svelte"),
	() => import("..\\..\\src\\routes\\furnishing\\index.svelte"),
	() => import("..\\..\\src\\routes\\furnishing\\inventory.svelte"),
	() => import("..\\..\\src\\routes\\furnishing\\list.svelte"),
	() => import("..\\..\\src\\routes\\index.svelte"),
	() => import("..\\..\\src\\routes\\items.svelte"),
	() => import("..\\..\\src\\routes\\privacy-policy.svelte"),
	() => import("..\\..\\src\\routes\\radiant-spincrystal\\index.svelte"),
	() => import("..\\..\\src\\routes\\reminder\\index.svelte"),
	() => import("..\\..\\src\\routes\\settings\\debug.svelte"),
	() => import("..\\..\\src\\routes\\settings\\index.svelte"),
	() => import("..\\..\\src\\routes\\tcg\\index.svelte"),
	() => import("..\\..\\src\\routes\\test.svelte"),
	() => import("..\\..\\src\\routes\\timeline\\index.svelte"),
	() => import("..\\..\\src\\routes\\todo.svelte"),
	() => import("..\\..\\src\\routes\\weapons\\[id].svelte"),
	() => import("..\\..\\src\\routes\\weapons\\index.svelte"),
	() => import("..\\..\\src\\routes\\wish\\[id].svelte"),
	() => import("..\\..\\src\\routes\\wish\\import.svelte"),
	() => import("..\\..\\src\\routes\\wish\\index.svelte"),
	() => import("..\\..\\src\\routes\\wish\\tally\\index.svelte")
];

export const dictionary = {
	"": [[0, 16], [1]],
	"achievement": [[0, 2], [1]],
	"artifacts": [[0, 4], [1]],
	"banners": [[0, 5], [1]],
	"calculator": [[0, 6], [1]],
	"calendar": [[0, 7], [1]],
	"changelog": [[0, 8], [1]],
	"characters": [[0, 10], [1]],
	"fishing": [[0, 12], [1]],
	"furnishing": [[0, 13], [1]],
	"items": [[0, 17], [1]],
	"privacy-policy": [[0, 18], [1]],
	"radiant-spincrystal": [[0, 19], [1]],
	"reminder": [[0, 20], [1]],
	"settings": [[0, 22], [1]],
	"tcg": [[0, 23], [1]],
	"test": [[0, 24], [1]],
	"timeline": [[0, 25], [1]],
	"todo": [[0, 26], [1]],
	"weapons": [[0, 28], [1]],
	"wish": [[0, 31], [1]],
	"furnishing/inventory": [[0, 14], [1]],
	"furnishing/list": [[0, 15], [1]],
	"settings/debug": [[0, 21], [1]],
	"wish/import": [[0, 30], [1]],
	"wish/tally": [[0, 32], [1]],
	"artifacts/[id]": [[0, 3], [1]],
	"characters/[id]": [[0, 9], [1]],
	"domains/[id]": [[0, 11], [1]],
	"weapons/[id]": [[0, 27], [1]],
	"wish/[id]": [[0, 29], [1]]
};